const hobbies = [
    'Other',
    '3D printing',
    'ATV Riding',
    'Acrobatics',
    'Acting',
    'Aircraft Spotting',
    'Airsoft',
    'Amateur Astronomy',
    'Amateur Radio',
    'American Football',
    'Angling',
    'Animal Rearing',
    'Animal Husbandry',
    'Animal Keeping',
    'Animating',
    'Animation',
    'Antiquing',
    'Antiquities',
    'Aquascaping',
    'Archery',
    
    'Architecture',
    'Art collecting',
    'Arts & Crafts',
    'Astronomy',
    'Athletics',
    'Audiophile',
    'Australian Football',
    'Auto audiophilia',
    'Auto racing',
    'Axe throwing',
    'Backgammon',
    'Badminton',
    'Baking',
    'Barbecuing',
    'Baseball',
    'Basketball',
    'Bath bombs',
    'Baton twirling',
    'Beach volleyball',
    'Beading',
    'Beauty pageants',
    'Bicycling',
    'Billiards',
    'Birdwatching',
    'Blogging',
    'Board games',
    'Boating',
    'Book Collecting',
    'Book Clubs',
    'Book Restoration',
    'Bow Making',
    'Bowling',
    'Bowls',
    'Boxing',
    'Brainy Hobbies',
    'Brazilian jiu-jitsu',
    'Breadmaking',
    'Breakdancing',
    'Bridge',
    'Building Electronics',
    'Bullet journaling',
    'Bus spotting',
    'Button collecting',
    'Cabaret',
    'Cake decorating',
    'Cake making',
    'Calligraphy',
    'Camping',
    'Candle making',
    'Canning',
    'Canoeing',
    'Car boot sale',
    'Car fixing',
    'Car racing',
    'Caramboard game',
    'Card games',
    'Cartophily',
    'Checkers',
    'Cheerleading',
    'Cheesemaking',
    'Chess',
    'Chicken Rearing',
    'Circuit crafts',
    'Cleaning',
    'Climbing',
    'Clothes making',
    'Coding',
    'Coffee roasting',
    'Coin collecting',
    'Color guard',
    'Coloring',
    'Colouring',
    'Compelling playlists',
    'Computer programming',
    'Confectionery',
    'Cooking',
    'Cosplaying',
    'Couponing',
    'Craft',
    'Creative writing',
    'Cribbage',
    'Cricket',
    'Crochet',
    'Crocheting',
    'Croquet',
    'Cross-stitch',
    'Crossoword/Sudoku',
    'Crossword puzzles',
    'Cryptography',
    'Cue sports',
    'Curling',

    'DIY',
    'DJing',
    'Dance',
    'Dancing',
    'Darts',

    'Debates',
    'Debating',
    'Decorating',
    'Deltiology',
    'Die-cast toy',
    'Digital arts',
    'Disability Sport',
    'Disc golf',
    'Distro Hopping',
    'Diving',
   
    'Do it yourself',
    'Dog sport',
    'Doll making',
    'Dolls',
    'Dominoes',
    'Drama',
    'Drawing',
    'Drink mixing',
    'Driving',
    'Eating different cuisines',
    'Electronic Sport',
    'Electronic games',
    'Electronics',
    'Element collecting',
    'Embroidery',
    'Ephemera collecting',
    'Equestrian',
    'Equestrianism',
    'Esports',
    'Exhibition drill',
    'Experimenting',
    'Exploration',
    'Exploring',
    'Fabric paining',
    'Fantasy sports',
    'Fashion',
    'Fashion design',
    'Fencing',
    'Field hockey',
    'Figure Collecting',
    'Figure skating',
    'Fishing',
    'Fishkeeping',
    'Fitness & training',
    'Fitness classes',
    'Flipping furniture',
    'Flipping items',
    'Flower arranging',
    'Flower collecting',
    'Fly tying',
    'Flying',
    'Food tasting',
    'Footbag',
    'Football',
    'Formula 1',
    'Fossil hunting',
    'Foster care',
    'Freelancing',
    'Frisbee',
    'Fruit carving',
    'Fundraising',
    'Furniture building',
    'Fusilately',
    'Gaelic Games',
    'Gaming',
    'Gardening',
    'Genealogy',
    'Geocaching',
    'Gingerbread making',
    'Glamping',
    'Glassblowing',
    'Go-karting',
    'Golf',
    'Golfing',
    'Gongoozling',
    'Graphic design',
    'Grilling',
    'Gunsmithing',
    'Gymnastics',
    'Hacking',
    'Handball',
    'Herp keeping',
    'Herping',
    'Hiking/Backpacking',
    'Hockey',
    'Home improvement',
    'Homebrewing',
    'Horse Racing',
    'Horseback riding',
    'Horseshoes',
    'Houseplant care',
    'Hula hooping',
    'Hunting',
    'Hydroponics',
    'Ice Hockey',
    'Ice hockey',
    'Ice skating',
    'Iceboat racing',
    'Insect collecting',
    'Instagram',
    'Interior design',
    'Jewellery making',
    'Jigsaw puzzles',
    'Jogging',
    'Journaling',
    'Judo',

    'Juggling',
    'Jujitsu',
    'Jukskei',
    'Jumble sale',
    'Kabaddi',
    'Karaoke',
    'Karate',
    'Kart racing',
    'Kendama',
    'Kickboxing',
    'Knife collecting',
    'Knife making',
    'Knife throwing',
    'Knitting',
    'Knot tying',
    'Kombucha brewing',
    'Lace making',
    'Lacrosse',
    'Landscaping',
    'Lapidary',
    'Laser tag',
    'Leaf Pressing',
    'Learning new things',
    'Learning new languages',
    'Learning new skills',
    'Leather crafting',
    'Lego building',
    'Listening to music',
    'Listening to nasheeds',
    'Listening to podcasts',
    'Lock picking',
    'Long-distance running',
    'Longboarding',
    'Machining',
    'Macrame',
    'Magic',
    'Magnet fishing',
    'Mahjong',
    'Makeup',
    'Marbles',
    'Marching band',
    'Martial arts',
    'Mazes',
    'Meditation',
    'Memorabilia collecting',
    'Mentoring',
    'Metal detecting',
    'Metalworking',
    'Meteorology',
    'Microscopy',
    'Minature crafting',
    'Mineral collecting',
    'Mixed Martial Arts',
    'Model United Nations',
    'Model aircraft',
    'Model building',
    'Model engineering',
    'Model racing',
    'Modelling',
    'Moderating online group',
    'Modern Pentathlon',
    'Mosaics',
    'Motorsport',
    'Music lessons',
    'Nail art',
    'Needlepoint',
    'Netball',
   
    'Netball volleyball',
    'Oil painting',
    'Olympic Sports',
    'Online courses',
    'Online selling',
    'Orienteering',
    'Origami',
    'Outdoors',
    'Outing',
    
    'Paintballing',
    'Painting',
    'Perfume',
    'Pet Fostering',
    'Philately',
    'Phillumeny',
    'Photography',
    'Pilates',
    'Pin (lapel)',
    'Pintrest',
    'Playing an Instrument',
    'Podcasting',
    'Poetry',
    'Poi',
    'Poker',
    'Pool',
    'Pottery',
    'Powerboat racing',
    'Powerlifting',
    'Practical jokes',
    'Printing',
    'Programming',
    'Proofreading',
    'Public Speaking',
    'Putting together models',
    'Puzzles',
    'Quidditch',
    'Quilling',

    'Quizzes',
    'Racing tournaments',
    'Race walking',
    'Racquetball',
    'Rapping',
    'Record collecting',
    'Refinishing',
    'Reiki',
    'Research',
    'Researching',
    'Road trips',
    'Robot combat',
    'Rock balancing',
    'Rock tumbling',
    'Role-playing games',
    'Roller derby',
    'Rowing',
    "Rubik's Cube",
    'Rugby League',
    'Rugby Union',
    'Rugby league football',
    'Running',
    'Sailing',
    'Sand Art',
    'Satellite watching',
    'School volunteering',
    'Science Experiments',
    'Scrapbooking',
    'Sculling or rowing',
    'Sculpting',
    'Scutelliphily',
    'Sea glass collecting',
    'Seashell collecting',
    'Series/Movie watching',
    'Sewing',
    'Shoemaking',
    'Shoes',
    'Shogi',
    'Shooting',
    'Shooting sport',
    'Shopping',
    'Shortwave listening',
    'Singing',
    'Skateboarding',
    'Sketching',
    'Skiing',
    'Sled dog racing',
    'Slot car racing',
    'Snapchat',
    'Snooker',
    'Snorkelling',
    'Soap making',
    'Soapmaking',
    'Social media',
    'Sodoku',
    'Softball',
    'Song writing',
    'Speed skating',
    'Speedcubing',
    'Sport stacking',
    'Sports memorabilia',
    'Squash',
    'Stamp collecting',
    'Stand-up comedy',
    'Stone collecting',
    'Story telling',
    'Story writing',
    'Stuffed toy collecting',
    'Supporting football clubs',
    'Surfing',
    'Swimming',

    'Table Tennis',
    'Table football',
    'Table tennis',
    'Taekwondo',
    'Tarot',
    'Taxidermy',
    'Tea bag collecting',
    'Tennis',

    'Tennis polo',
    'Tether car',
    'Thrifting',
    'Ticket collecting',
    'Tiktok',
    'Tour skating',
    'Toys',
    'Trainspotting',
    'Transport modelling',
    'Trapshooting',
    'Travelling',
    'Triathlon',

    'Tutoring',
    'Tweeting',
    'Twitch channel',
    'Ultimate frisbee',
    'Video Games',
    'Video editing',
    'Video game developing',
    'Video gaming',
    'Videophilia',
    'Vintage cars',
    'Vintage clothing',
    'Vinyl Records',
    'Virtual assistant',
    'Vlogging',
    'Volleyball',

    'Volunteering',
    'Walking',
    'Watching movies',
    'Watching television',
    'Water aerobics',
    'Water polo',
    'Watercolour painting',
    'Waxing',
    'Wealth making',
    'Weaving',
    'Weight training',
    'Weightlifting',

    'Welding',
    'Whale watching',
    'Whittling',
    'Wikipedia editing',
    'Wild camping',
    'Winemaking',
    'Winter Sports',
    'Wood burning',

    'Woodworking',
    'Word searches',
    'Word games',
    'Working out',
    'Worldbuilding',
    'Wrestling',
   
    'Writing',
    'Yo-yoing',
    'Yoga',
    'Youtube channel',
    'Zumba',
];

export default hobbies;
